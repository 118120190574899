import {
  AppBar,
  Icon,
  MuiThemeProvider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { appTheme } from "../riskAssessment/brmDetaails";
import { appbarStyles } from "../esgCompanyProfile/esgCompantProfile";
import TabPanel from "../../UI/tabPanel/tabPanel";
import EsgScore from "../esgCompanyProfile/esgScore";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CompanyProfile from "../esgCompanyProfile/companyProfile";
import DimentionCharts from "../esgDeclaration/dimentionChart/dimentionCharts";
import VendorScore from "../esgCompanyProfile/vendorScore";
import { Radar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_default_surveys,
  gql_get_tprm_score,
  gql_get_vendor_Assessment_score,
} from "../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_TPRM_SCORE } from "../../../constants/esgDiagnosisConstant";
import VendorMaterialityScore from "../esgCompanyProfile/VendorMaterialityScore";
import { SET_SHOW_SURVEYS } from "../../../constants/brmConstants";
import VendorIQ from "../publicInfo/dialogBox/VendorIQ";
import VendorIqTab from "../esgCompanyProfile/VendorIqTab";
import KypTab from "../esgCompanyProfile/KypTab";

function generatedDummyDataset(len) {
  const datasets = [];
  for (let i = 0; i < len; i++) {
    const dataset = {
      label: "Compliance",
      data: new Array(len)?.fill(0),
      backgroundColor:
        "#" +
        ((Math?.random() * 0xffffff) << 0)?.toString(16)?.padStart(6, "0"),
      borderColor: "#E8A838",
      borderWidth: 1.5,
      pointStyle: "clearRect",
    };
    datasets?.push(dataset);
  }

  return datasets;
}

// const data = {
//   scoreObject: {
//     security: {
//       score: 8.692905242905244,
//       sum_of_weighted_score: 0.08692905242905244,
//       final_weighted_score: 0.008692905242905244,
//       average: {
//         analytics: 0.355978835978836,
//         intrusion: 0.25665584415584414,
//         log: 0.25665584415584414,
//         file: 0.25665584415584414,
//         vulnerablity: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         container: 0.25665584415584414,
//         cloud: 0.25665584415584414,
//       },
//       weighted_score: {
//         analytics: 0.0355978835978836,
//         intrusion: 0.05133116883116883,
//         log: 0.25665584415584414,
//         file: 0.25665584415584414,
//         vulnerablity: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         container: 0.25665584415584414,
//         cloud: 0.25665584415584414,
//       },
//       data_points: {
//         analytics: 3.5,
//         intrusion: 5.1,
//         log: 4.5,
//         file: 2,
//         vulnerablity: 1.4,
//         incident: 3,
//         container: 3,
//         cloud: 3,
//       },
//     },
//     compilance: {
//       score: 8.692905242905244,
//       sum_of_weighted_score: 0.08692905242905244,
//       final_weighted_score: 0.008692905242905244,
//       average: {
//         configure: 0.355978835978836,
//         regulatory: 0.25665584415584414,
//         vendor: 0.25665584415584414,
//         audit: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         data: 0.25665584415584414,
//         concent: 0.25665584415584414,
//       },
//       weighted_score: {
//         configure: 0.0355978835978836,
//         regulatory: 0.05133116883116883,
//         vendor: 0.25665584415584414,
//         audit: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         data: 0.25665584415584414,
//         concent: 0.25665584415584414,
//       },
//       data_points: {
//         configure: 3.5,
//         regulatory: 5.1,
//         vendor: 4.5,
//         audit: 2,
//         incident: 1.4,
//         data: 3,
//         concent: 3,
//       },
//     },
//     audit: {
//       score: 8.692905242905244,
//       sum_of_weighted_score: 0.08692905242905244,
//       final_weighted_score: 0.008692905242905244,
//       average: {
//         analytics: 0.355978835978836,
//         intrusion: 0.25665584415584414,
//         log: 0.25665584415584414,
//         file: 0.25665584415584414,
//         vulnerablity: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         container: 0.25665584415584414,
//         cloud: 0.25665584415584414,
//       },
//       weighted_score: {
//         analytics: 0.0355978835978836,
//         intrusion: 0.05133116883116883,
//         log: 0.25665584415584414,
//         file: 0.25665584415584414,
//         vulnerablity: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         container: 0.25665584415584414,
//         cloud: 0.25665584415584414,
//       },
//       data_points: {
//         analytics: 3.5,
//         intrusion: 5.1,
//         log: 4.5,
//         file: 2,
//         vulnerablity: 1.4,
//         incident: 3,
//         container: 3,
//         cloud: 3,
//       },
//     },
//     threat: {
//       score: 2.692905242905244,
//       sum_of_weighted_score: 0.08692905242905244,
//       final_weighted_score: 0.008692905242905244,
//       average: {
//         configure: 0.355978835978836,
//         regulatory: 0.25665584415584414,
//         vendor: 0.25665584415584414,
//         audit: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         data: 0.25665584415584414,
//         concent: 0.25665584415584414,
//       },
//       weighted_score: {
//         configure: 0.0355978835978836,
//         regulatory: 0.05133116883116883,
//         vendor: 0.25665584415584414,
//         audit: 0.25665584415584414,
//         incident: 0.25665584415584414,
//         data: 0.25665584415584414,
//         concent: 0.25665584415584414,
//       },
//       data_points: {
//         configure: 3.5,
//         regulatory: 5.1,
//         vendor: 4.5,
//         audit: 2,
//         incident: 1.4,
//         data: 3,
//         concent: 3,
//       },
//     },
//   },
//   esgScore: 2.98661827925614,
//   esgcScore: 5.555555555555555,
//   finalEsg: 50.77108691740585,
//   energy_kpis: {
//     "Total electricity consumption in Gigajoules  from renewable sources": 0,
//     "Total fuel consumption in Gigajoules  from renewable sources": 0,
//     "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
//     "Total electricity consumption in Gigajoules  from non renewable sources": 57122.44,
//     "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
//     "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
//   },
//   water_kpis: {
//     "wasteCount withdrawal by source (in kilolitres)  from surface water": 0,
//     "Water withdrawal by source (in kilolitres)  from ground water": 0,
//     "Water withdrawal by source (in kilolitres)  from third party water ": 0,
//     "Amount of rainwater was utilized  (in kilolitres) ": 0,
//     " Volume of water withdrawal  (in kilolitres)": 0,
//     "Total volume of water consumption (in kilolitres)  ": 78400,
//     " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
//   },
// };

const data = {
  scoreObject: {
    security_information_management: {
      score: 42.8,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        security_events: 0.355978835978836,
        integrity_monitoring: 0.25665584415584414,
      },
      weighted_score: {
        security_events: 0.0355978835978836,
        integrity_monitoring: 0.05133116883116883,
      },
      data_points: {
        security_events: 59.5,
        integrity_monitoring: 17.81,
      },
    },
    regulatory_compilance: {
      score: 72.1,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        nist: 0.355978835978836,
      },
      weighted_score: {
        nist: 0.0355978835978836,
      },
      data_points: {
        nist: 72.1,
      },
    },
    audit_and_policy_monitoring: {
      score: 53.5,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        policy_monitoring: 0.355978835978836,
        security_configuration_assessment: 0.25665584415584414,
      },
      weighted_score: {
        policy_monitoring: 0.0355978835978836,
        security_configuration_assessment: 0.05133116883116883,
      },
      data_points: {
        policy_monitoring: 53.5,
        // security_configuration_assessment: 0,
      },
    },
    threat_detection_and_response: {
      score: 65.6,
      sum_of_weighted_score: 0.08692905242905244,
      final_weighted_score: 0.008692905242905244,
      average: {
        vulnerabilities: 0.355978835978836,
        mitre_attack: 0.25665584415584414,
      },
      weighted_score: {
        // vulnerabilities: 0.0355978835978836,
        mitre_attack: 0.05133116883116883,
      },
      data_points: {
        vulnerabilities: 0,
        mitre_attack: 65.6,
      },
    },
  },
  esgScore: 2.98661827925614,
  esgcScore: 5.555555555555555,
  finalEsg: 58.52,
  energy_kpis: {
    "Total electricity consumption in Gigajoules  from renewable sources": 0,
    "Total fuel consumption in Gigajoules  from renewable sources": 0,
    "Total Energy consumption  in Gigajoules  from renewable sources ": 0,
    "Total electricity consumption in Gigajoules  from non renewable sources": 57122.44,
    "Total fuel consumption in Gigajoules  fromnon renewable sources": 0,
    "Total Energy consumption  in Gigajoules  from non renewable sources ": 0,
  },
  water_kpis: {
    "wasteCount withdrawal by source (in kilolitres)  from surface water": 0,
    "Water withdrawal by source (in kilolitres)  from ground water": 0,
    "Water withdrawal by source (in kilolitres)  from third party water ": 0,
    "Amount of rainwater was utilized  (in kilolitres) ": 0,
    " Volume of water withdrawal  (in kilolitres)": 0,
    "Total volume of water consumption (in kilolitres)  ": 78400,
    " Water intensity per rupee of turnover (Water consumed / turnover) ": 0,
  },
};

function VendorProfile(props) {
  const [vendorProfileTabNo, setVendorProfileTabNo] = useState(0);
  const { bankId } = useSelector((state) => state?.login);
  const { showSurveys, vendorBasicDetails } = useSelector(
    (state) => state?.brmData
  );
  const { tprmScore, vendorAssessmentScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const storedVendorId = localStorage.getItem("vendorId");
  const storedBankId = localStorage.getItem("bank_id");
  const storedVendorIqId = localStorage.getItem("vendoriq_id");

  const [totalTprmScore, setTotalTprmScore] = useState(0);

  const [graph1Data, setGraph1Data] = useState({
    labels: [
      "Labour Standards",
      "Human Rights",
      "Physical and Environmental Security",
      "Environment",
      "Access Control",
    ],
    datasets: generatedDummyDataset(5),
  });
  const [graph2Data, setGraph2Data] = useState({
    labels: [
      "Information Security",
      "Asset Management",
      "Data Privacy",
      "Cloud Specific Requirements",
      "Compliance Compliance",
    ],
    datasets: generatedDummyDataset(5),
  });
  const [graph3Data, setGraph3Data] = useState({
    labels: [
      "Risk Management",
      "Business Continuity Management",
      "vulnerability and threat management",
      "Business and Ethical Conduct",
      "Third Party/Subcontractors",
      "Organizational Security",
    ],
    datasets: generatedDummyDataset(6),
  });

  const defaultData = {
    labels: [
      "Labour Standards",
      "Human Rights",
      "Physical and Environmental Security",
      "Environment",
      "Access Control",
    ],
    datasets: [],
  };

  const app = appbarStyles();
  const dispatch = useDispatch();

  const updateGraphData = (key, score, state, setState) => {
    const idx = state?.labels?.findIndex((item) => item === key);
    if (idx === -1) return;
    let datasets = state.datasets;
    let datapoints = datasets[idx]?.data;
    datapoints[idx] = score;
    datasets[idx] = {
      ...datasets[idx],
      data: [...datapoints],
      label: key,
    };
    setState({ ...state, datasets: [...datasets] });
  };

  useEffect(() => {
    dispatch(gql_get_default_surveys(bankId || storedBankId));

    return () => {
      dispatch({ type: SET_SHOW_SURVEYS, payload: [] });
    };
  }, []);

  useEffect(() => {
    const doraTprm = showSurveys.find(
      (survey) => survey.name.toLowerCase() === "dora tprm"
    );

    if (doraTprm && vendorProfileTabNo === 2) {
      const getScoreInput = {
        vendorId: vendorBasicDetails?._id || storedVendorId,
        surveyId: doraTprm?._id,
      };
      dispatch(gql_get_tprm_score(getScoreInput));
    }

    return () => {
      dispatch({ type: SET_TPRM_SCORE, payload: {} });
      setTotalTprmScore(0);
    };
  }, [showSurveys, vendorProfileTabNo]);

  useEffect(() => {
    const keys = Object.keys(tprmScore);
    if (keys.length > 0) {
      let total = 0;
      let sum = 0;
      keys?.forEach((key) => {
        const score = tprmScore[key].score;
        const categories = tprmScore[key].categories;
        const categorieKeys = Object?.keys(categories);
        const categoryScore = categories[categorieKeys[0]];
        if (graph1Data.labels.includes(categorieKeys[0])) {
          updateGraphData(
            categorieKeys[0],
            categoryScore,
            graph1Data,
            setGraph1Data
          );
        } else if (graph2Data.labels.includes(categorieKeys[0])) {
          updateGraphData(
            categorieKeys[0],
            categoryScore,
            graph2Data,
            setGraph2Data
          );
        } else {
          updateGraphData(
            categorieKeys[0],
            categoryScore,
            graph3Data,
            setGraph3Data
          );
        }
        sum += score;
        total = (sum / (keys.length * 10)) * 100;
      });

      setTotalTprmScore(total);
    }
  }, [tprmScore]);

  // const options = {
  //   legend: {
  //     labels: {
  //       usePointStyle: true, //<-- set this
  //     },
  //   },
  // };

  // const config3 = {
  //   type: "radar",
  //   data: graphdata,
  //   options: options,

  //   // {
  //   //   scale: {
  //   //     beginAtZero: true,
  //   //     min: 0,
  //   //   },
  //   //   elements: {
  //   //     line: {
  //   //       borderWidth: 1,
  //   //     },
  //   //   },
  //   // },
  // };

  const config = {
    type: "radar",
    // data: graphdata,
    options: {
      // plugins: {
      //   scale: {
      //     beginAtZero: true,
      //     min: 0,
      //   },
      //   legend: {
      //     labels: {
      //       usePointStyle: true, //<-- set this
      //     },
      //   },
      // },
      // interaction: {
      //   intersect: false,
      // },
    },
  };
  const options1 = {
    // maintainAspectRatio: false,
    scales: {
      r: {
        suggestedMin: 0,
        suggestedMax: 1,
        // pointLabels: {
        //   display: false, // Hides the labels around the radar chart
        // },
        ticks: {
          display: false, // Hides the labels in the middel (numbers)
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true, //<-- set this
      },
    },
  };

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Vendor Profile
          </Typography>
        </div>
        <div>
          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          /> */}
          <Icon style={{ cursor: "pointer", color: "#3374b9", fontSize: 20 }}>
            more_vert
          </Icon>
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={app.AppBar}>
          <Tabs
            value={vendorProfileTabNo}
            onChange={(e, newVal) => {
              setVendorProfileTabNo(newVal);
            }}
            // onChange={handleTabChange}
          >
            <Tab
              value={0}
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Materiality</span>}
            />

            <Tab
              value={1}
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Score</span>}
            />
            <Tab
              value={2}
              disableRipple
              className={app.tab}
              label={<span className={app.tabLabel}>Vendor Dataform</span>}
            />
            {(vendorBasicDetails?.vendoriq_id ||
              storedVendorIqId !== "null") && (
              <Tab
                value={3}
                disableRipple
                className={app.tab}
                label={<span className={app.tabLabel}>Vendor iQ</span>}
              />
            )}

            {vendorBasicDetails?.kypId && (
              <Tab
                value={4}
                disableRipple
                className={app.tab}
                label={<span className={app.tabLabel}>Know Your Partner</span>}
              />
            )}
          </Tabs>
        </AppBar>
      </MuiThemeProvider>

      <div
        className="scroll"
        style={{ height: "51vh", marginTop: "10px", paddingBottom: 20 }}
      >
        <TabPanel value={vendorProfileTabNo} index={1}>
          {<VendorScore scores={data} />}
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={2}>
          <div className="scroll" style={{ height: "55vh" }}>
            <div style={{ display: "flex", gap: 10 }}>
              <div style={{ width: "40%" }}>
                <BlueBackgroundCard heading={"TPRM Score"} />
                <CompanyProfile
                  name={"TPRM Score"}
                  scores={{ score: totalTprmScore }}
                  isFrom={"vendorDiagnosis"}
                  description={
                    "Conduct thorough dataforms of vendor risk, compliance, and performance. Use a structured framework for consistent and comprehensive analysis."
                  }
                />
              </div>
              <div style={{ width: "60%" }}>
                <BlueBackgroundCard heading={"Dimensions"} />
                {Object?.keys(tprmScore)?.length > 0 ? (
                  <Paper>
                    <div style={{ width: "80%", margin: "0 auto" }}>
                      <Radar
                        config={config}
                        options={options1}
                        data={graph1Data}
                      />
                      <Radar
                        config={config}
                        options={options1}
                        data={graph2Data}
                      />
                      <Radar
                        config={config}
                        options={options1}
                        data={graph3Data}
                      />
                    </div>
                  </Paper>
                ) : (
                  <Paper>
                    <div style={{ width: "80%", margin: "0 auto" }}>
                      <Radar
                        config={config}
                        options={options1}
                        data={defaultData}
                      />
                    </div>
                  </Paper>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={0}>
          <VendorMaterialityScore />
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={3}>
          <VendorIqTab />
        </TabPanel>
        <TabPanel value={vendorProfileTabNo} index={4}>
          <KypTab />
        </TabPanel>
      </div>
    </div>
  );
}

export default VendorProfile;
