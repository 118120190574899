import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

// redux imports
// import { useSelector } from "react-redux";

// icons
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { useSelector } from "react-redux";
import { toDateYear } from "../../../../../../../../utils/dateTime/convertTimestamp";

const useStyles = makeStyles((theme) => ({
  rootSelect: {
    padding: "5px 14px",
  },
}));

const ResponseSectionTopCard = ({
  selectedResCompany,
  total_no_of_questions,
  surveyStatus,
  setFrequencyId,
  handleFrequencyChange,
}) => {
  const classes = useStyles();
  // from redux
  //   const { selectedResCompany?, total_no_of_questions } = useSelector(
  //     (state) => state.riskDashboard
  //   );
  const [dropdownValue, setDropdownValue] = useState("");

  const { frequencies, completedQuestions } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { showSelectedSurvey } = useSelector((state) => state.brmData);

  const noOfQuestionsCompleted = completedQuestions?.reduce(
    (cur, acc) => cur + acc?.questions?.length,
    0
  );

  const noOfQuestions = showSelectedSurvey?.sections?.sections?.reduce(
    (cur, acc) => acc?.noOfQuestions + cur,
    0
  );

  // console.log(noOfQuestions);
  // console.log(noOfQuestionsCompleted);

  // --------------- inner functions ---------------
  useEffect(() => {
    setDropdownValue(
      `${toDateYear(frequencies[0]?.startDate)} - ${toDateYear(
        frequencies[0]?.endDate
      )}`
    );
  }, [frequencies]);

  // Function to handle dropdown value change
  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
  };

  // Function to generate options for popup
  const MakeCard = ({ heading, value, type }) => {
    const key = `${heading}-${value}`;
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      <Box
        key={key}
        sx={{
          m: 1,
          width: "100%",
          height: "96px",
          borderRadius: "8px",
          padding: "16px 0px 16px 0px",
          backgroundColor: "#F6F6F6",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            marginBottom: 10,
            fontWeight: "500",
            fontSize: "13px",
            lineHeight: "21px",
            color: "#15314E",
          }}
        >
          {heading}
        </Typography>
        {type === "status" ? (
          <Box
            sx={{
              padding: "4px 8px 4px 8px",
              borderRadius: "80px",
              display: "flex",
              alignItems: "center",
              width: "max-content",
              height: "24px",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: value ? "#154328" : "#8C7038",
              backgroundColor: value ? "#BFE2CD" : "#FFEFD0",
            }}
          >
            <FiberManualRecordIcon
              style={{ width: 8, height: 8, marginRight: 5 }}
            />
            {value ? "Completed" : "Incomplete"}
          </Box>
        ) : type === "common" ? (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        ) : type === "popup-quoter" || type === "popup-monthly" ? (
          <Select
            value={dropdownValue}
            onChange={(e) => {
              handleDropdownChange(e);
            }}
            variant="outlined"
            classes={{ root: classes.rootSelect }}
            style={{
              width: "80%",
              maxHeight: "30px",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#15314E",
              boxShadow: "#C0D4E9",
              backgroundColor: "#FEFEFE",
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  padding: "0px 6px",
                },
              },
            }}
          >
            {value.map((freq) => (
              <MenuItem
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    dropdownValue ===
                    `${toDateYear(freq?.startDate)}} - ${toDateYear(
                      freq?.endDate
                    )}`
                      ? "#EBF1F8"
                      : "transparent",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "24px",
                }}
                onClick={() => handleFrequencyChange(freq?._id)}
                key={freq._id}
                value={`${toDateYear(freq?.startDate)} - ${toDateYear(
                  freq?.endDate
                )}`}
              >
                {toDateYear(freq?.startDate)} - {toDateYear(freq?.endDate)}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Typography
            style={{
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "22px",
              color: "#15314E",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    );
  };

  // -----------------------------------------------

  return (
    <Box
      sx={{
        m: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* <MakeCard
        heading="Role of the stakeholder"
        value={selectedResCompany?.role}
        type={"common"}
      /> */}
      <MakeCard
        heading="Dataform status"
        value={noOfQuestions === noOfQuestionsCompleted || surveyStatus}
        type={"status"}
      />
      <MakeCard
        heading="No. of Dataform Questions"
        value={noOfQuestionsCompleted || total_no_of_questions}
        type={"common"}
      />
      <MakeCard
        heading={`Frequency: ${frequencies[0]?.type}`}
        value={frequencies}
        type={"popup-monthly"}
      />
    </Box>
  );
};

export default ResponseSectionTopCard;
